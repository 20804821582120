// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politika-kolacica-js": () => import("./../../../src/pages/politika-kolacica.js" /* webpackChunkName: "component---src-pages-politika-kolacica-js" */),
  "component---src-pages-politika-privatnosti-js": () => import("./../../../src/pages/politika-privatnosti.js" /* webpackChunkName: "component---src-pages-politika-privatnosti-js" */),
  "component---src-pages-uslovi-koriscenja-js": () => import("./../../../src/pages/uslovi-koriscenja.js" /* webpackChunkName: "component---src-pages-uslovi-koriscenja-js" */),
  "component---src-pages-vesti-whois-hakaton-js": () => import("./../../../src/pages/vesti/whois-hakaton.js" /* webpackChunkName: "component---src-pages-vesti-whois-hakaton-js" */)
}

